import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { setNotificationCount, setNotificationData } from '../../redux/authentication';

// Create a Context for the socket
const SocketContext = createContext(null);

export const useSocket = () => {
    return useContext(SocketContext);
};

// Socket Provider component
export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null);
    const [socket, setSocket] = useState(null);
    const notiData = useSelector(state => state.auth.notification) || []

    const isLogin = localStorage.getItem('1oCloth-admin-token')
    const dispatch = useDispatch()
    useEffect(() => {
        // Connect the socket when the component mounts
        if (isLogin) {
            socketRef.current = io.connect(
                // "http://localhost:5102",
                "https://api.1ocloth.com",
                {
                    query: { token: localStorage.getItem('1oCloth-admin-token') },
                });

            socketRef.current.on('connect', () => {
                console.log('Socket connected');
            });

            socketRef.current.on('notification', (notification) => {
                const nData = [notification, ...notiData]; // Adds the notification object to the beginning of notiData
                dispatch(setNotificationData(nData));
            });
            
            socketRef.current.on('notification-count', (count) => {
                dispatch(setNotificationCount(count))
            });

            socketRef.current.on('authentication', (authentication) => {
                setSocket(socketRef.current)
                console.log(authentication, 'authentication');
            });

            socketRef.current.on('disconnected', (disconnected) => {
                console.log(disconnected, 'disconnected');
            });

            socketRef.current.on('connect_error', (error) => {
                console.error('WebSocket connection error:', error);
            });
        } else {
            return () => {
                if (socketRef.current) {
                    socketRef.current.disconnect();
                }
            };

        }
        // Cleanup when the component unmounts

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };

    }, [isLogin]);

    // if (!socketInitialized) {
    //     return <div>Loading...</div>; // Render a loading state until the socket is connected
    // }

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
